import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { IContact, IUpdateContact } from 'models/customer-data-service/contact'
import { useSession } from 'next-auth/react'
import { customerContactsQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'

/**
 * Client site mutation to patch a single contact.
 *
 * @async
 * @param {IUpdateContact} contact - The model of the new contact state.
 * @returns {Promise<Response>} The server response.
 */
const contactUpdateMutationFn = async (
  contact: IUpdateContact
): Promise<Response> => {
  return await fetch('/api/contact/updateContact', {
    method: 'PATCH',
    body: JSON.stringify(contact),
  })
}

interface IContactUpdateMutationVariables {
  data: IUpdateContact
  successMessage: string
  errorMessage: string
}

/**
 * Mutation to update a single contact.
 *
 * @returns {UseMutationResult<IContact, void, IContactUpdateMutationVariables>} The mutation result.
 */
const useContactUpdateMutation = (): UseMutationResult<
  IContact | undefined,
  void,
  IContactUpdateMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (vars) => {
      try {
        const body = vars.data
        if (body.additionalPhoneNumber) {
          body.additionalPhoneNumber = body.additionalPhoneNumber.replaceAll(
            ' ',
            ''
          )
        }
        if (body.phoneNumber) {
          body.phoneNumber = body.phoneNumber.replaceAll(' ', '')
        }
        const response = await contactUpdateMutationFn(body)
        if (response.status !== 200) {
          throw new Error('could not update contact.')
        }
        const responseJson = await response.json()
        customToast.success(vars.successMessage)
        return responseJson as IContact
      } catch (error) {
        console.log(error)
        customToast.error(vars.errorMessage)
      }
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries({  queryKey:customerContactsQueryKey(session.user.id)}
         )
      }
    },
  })
}

export { useContactUpdateMutation }
