import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { client } from 'client'
import { GET_BANK_DATA_BY_IBAN } from 'graphql/mutations'
import { bankDataQueryKey } from 'reactQuery/queryKeys'

/**
 * Retrieve bank data by IBAN.
 *
 * @param {string} iban The IBAN to query for.
 * @returns {any} The bank data object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bankDataQueryFn = async (iban: string): Promise<any> => {
  const bankDataResponse = await client.query({
    query: GET_BANK_DATA_BY_IBAN,
    variables: {
      iban: iban,
    },
  })
  return bankDataResponse.data?.getBankDataByIban?.bankData
}

/**
 * Query hook to retrieve bank data by IBAN.
 *
 * @param {string} iban The iban to query for.
 * @returns {UseQueryResult<any>} The query result.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBankData = (iban: string): UseQueryResult<any> =>
  useQuery({
    queryKey: bankDataQueryKey(iban),
    queryFn: async () => {
      if (!iban) {
        return null
      }
      return await bankDataQueryFn(iban)
  }
    
  
  })

export { bankDataQueryFn, useBankData }
