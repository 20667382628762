import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { IContact } from 'models/salesforce-integrator/contact'
import { useSession } from 'next-auth/react'
import { customerContactsQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'

/**
 * Client side mutation to create a contact.
 *
 * @param {IContact} contact The model of the contact to create.
 * @returns {Promise<Response>} The server response.
 */
const createContactMutationFn = async (contact: IContact) => {
  return await fetch('/api/contact/create', {
    method: 'POST',
    body: JSON.stringify(contact),
  })
}

/** Custom model for the mutation variables. */
interface ICreateContactMutationVariables {
  /** The data to send with the request. */
  data: IContact
  /** The message to display on success. */
  successMessageText: string
  /** The message to display on error. */
  errorMessageText: string
}

/**
 * Mutation to create a contact.
 *
 * @returns {UseMutationResult<void>} The mutation result.
 */
const useCreateContactMutation = (): UseMutationResult<
  IContact | undefined,
  void,
  ICreateContactMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables) => {
      try {
        const body = { ...variables.data }
        if (body.mobilePhone) {
          body.mobilePhone = body.mobilePhone.replaceAll(' ', '')
        }
        if (body.phone) {
          body.phone = body.phone.replaceAll(' ', '')
        }
        const response = await createContactMutationFn(body)
        if (response.status !== 200) {
          throw new Error('could not create contact.')
        }
        customToast.success(variables.successMessageText)
        const updatedContact = (await response.json()) as IContact
        return updatedContact
      } catch (_error) {
        customToast.error(variables.errorMessageText)
      }
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries({  queryKey: customerContactsQueryKey(session.user.id)}
          
         )
      }
    },
  })
}

export { useCreateContactMutation, createContactMutationFn }
