import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { IContact } from 'models/customer-data-service/contact'
import { useSession } from 'next-auth/react'
import { customerContactsQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'
import { HttpMethod } from 'utils/types'

/**
 * Client side mutation to create a contact.
 *
 * @param {IContact} contact The model of the contact to create.
 * @returns {Promise<Response>} The server response.
 */
const contactCreateMutationFn = async (
  contact: IContact
): Promise<Response> => {
  return await fetch('api/contact/createContact', {
    method: HttpMethod.POST,
    body: JSON.stringify(contact),
  })
}

/** Custom model for the mutation variables. */
interface IContactCreateMutationVariables {
  /** The data to send with the request. */
  data: IContact
}

interface IContactCreateMutationParams {
  /** The message to display on success. */
  successMessageText: string
  /** The message to display on error. */
  errorMessageText: string
}

/**
 * Mutation to create a contact.
 *
 * @returns {UseMutationResult<IContact>} The mutation result.
 */
const useContactCreateMutation = ({
  successMessageText,
  errorMessageText,
}: IContactCreateMutationParams): UseMutationResult<
  IContact,
  void,
  IContactCreateMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ data }: IContactCreateMutationVariables) => {
      const body = { ...data }
      if (body.additionalPhoneNumber) {
        body.additionalPhoneNumber = body.additionalPhoneNumber.replaceAll(
          ' ',
          ''
        )
      }
      if (body.phoneNumber) {
        body.phoneNumber = body.phoneNumber.replaceAll(' ', '')
      }
      const response = await contactCreateMutationFn(body)
      if (response.status !== 200) {
        throw new Error('could not create contact.')
      }
      const updatedContact = (await response.json()) as IContact
      return updatedContact
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries({
          queryKey:  customerContactsQueryKey(session.user.id)
        })
      }
    },
    onSuccess: () => {
      customToast.success(successMessageText)
    },
    onError() {
      customToast.error(errorMessageText)
    },
  })
}

export { useContactCreateMutation }
